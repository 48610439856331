import { useStaticQuery, graphql } from "gatsby"

export const useWpStudiosQuery = () => {
  const data = useStaticQuery(graphql`
    query {
      milan: allWpStudio(
        filter: { studioLocation: { location: { eq: null } } }
      ) {
        edges {
          node {
            studioLocation {
              location
            }
            title
            slug
          }
        }
      }
      rome: allWpStudio(filter: { studioLocation: { location: { eq: "1" } } }) {
        edges {
          node {
            studioLocation {
              location
            }
            title
            slug
          }
        }
      }
    }
  `)

  return data
}
