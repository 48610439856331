import React from "react"
import styled from "styled-components"
import AutoTitle from "../AutoTitle"

const TitlesContainer = styled.div`
  .testosplash {
    margin-top: ${props => (props.isFirst !== null ? "50px" : "0")};
  }
`
const Title = ({ data, index }) => {
  return (
    <TitlesContainer isFirst={index === 0 ? true : false}>
      <div className="testosplash">
        {data.title !== null && <AutoTitle>{data.title}</AutoTitle>}
      </div>
    </TitlesContainer>
  )
}

export default Title
