import React from "react"
import styled from "styled-components"
import { useWpMenuQuery } from "../hooks/wp/useWpMenu"
import { Link } from "gatsby"
import { motion } from "framer-motion"

import "../styles/variables.scss"
import { useWpStudiosQuery } from "../hooks/wp/useWpStudios"

const MenuWrapper = styled(motion.div)`
  background-color: white;
  position: fixed;
  width: 100vw;
  height: 100vh;
  min-height: -webkit-fill-available;
  top: 0;
  left: 0;
  z-index: 9;
  mix-blend-mode: none;

  display: flex;
  flex-direction: row;
  align-items: flex-start;

  a {
    padding-bottom: 0.6em;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: inherit;
    writing-mode: tb-rl;
    transform: rotate(-180deg);

    padding-left: 0.2em;
    padding-right: 0.2em;
  }

  .link {
    font-size: 1.5rem;

    display: flex;
    align-items: center;
    justify-content: center;

    border-left: 1px solid black;
    align-items: flex-end;
    text-decoration: none;
    color: rgb(45, 45, 250);
    width: calc(100% / 36 * 3);
    height: 100%;

    &.close-btn {
      height: 100vh;
      background-color: transparent;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      border: none;
      color: transparent;
      &:hover {
        cursor: pointer;
      }

      .circle {
        position: fixed;
        z-index: 999;
        left: calc(50% - var(--menuBtnSize) / 2);
        top: 0.6em;
        border-radius: 50%;
        min-width: var(--menuBtnSize);
        min-height: var(--menuBtnSize);
        max-width: var(--menuBtnSize);
        max-height: var(--menuBtnSize);
        background-color: var(--red);
      }
    }

    a {
      text-decoration: none;
      text-transform: uppercase;
      height: 100vh;
      min-height: -webkit-fill-available;
      width: 100%;
      transition: all 0.2s;
      &:hover {
        color: var(--gray);
      }
    }
  }
  .sub-link {
    font-size: 1rem;

    align-items: center;
    width: calc(100% / 36);
    text-decoration: none;
    color: black;
  }

  @media (max-width: 768px) {
    .link {
      width: calc(100% / 24 * 3);
      font-size: 1rem;
    }
    .sub-link {
      font-size: 0.8rem;
      display: none !important;
    }
  }
`
const parent = {
  initial: { opacity: 0, x: "-100%" },
  animate: { opacity: 1, x: "0", transition: { staggerChildren: 0.05 } },
  exit: {
    opacity: 0,
    x: "-100%",
    transition: {
      duration: 0.2,
      when: "beforeChildren",
      // when: "afterChildren",
      // delayChildren: 1.5,
      staggerDirection: -1,
      staggerChildren: 0.05,
    },
  },
}

const children = {
  initial: { y: "-100%" },
  animate: { y: "0" },
  exit: { y: "-100%" },
}

const Menu = ({ menuState, setMenuState, width, location }) => {
  const { wpMenu } = useWpMenuQuery()
  const { milan, rome } = useWpStudiosQuery()

  // this produces a glitch when loading menu open
  // but it won't be open when the page loads so...
  let width_ = 0
  if (width !== undefined) {
    width_ = width / 36
  }

  const handleClick = targetPath => {
    if (location.pathname === targetPath) {
      setMenuState(false)
    }
  }

  const customOrder = ["XL", "L", "M + M2", "BLACK BOX"]
  const orderedMilan = milan.edges.sort((a, b) => {
    return customOrder.indexOf(a.node.title) - customOrder.indexOf(b.node.title)
  })

  return (
    <>
      <MenuWrapper
        width={width_}
        variants={parent}
        initial="initial"
        animate="animate"
        exit="exit"
      >
        <motion.button
          key="btn"
          variants={children}
          onClick={() => {
            setMenuState(!menuState)
          }}
          className="link close-btn"
        >
          <div className="circle" />
        </motion.button>

        {wpMenu.menuItems.nodes.map((node, i) => {
          if (node.connectedNode.node.title === "Studios") {
            return (
              <>
                <motion.div className="link" variants={children} key={i}>
                  <Link
                    activeClassName="active"
                    to={"/studios/milan/"}
                    state={{ page: node.path }}
                    onClick={() => handleClick(node.path)}
                  >
                    Milano
                    {/* {node.connectedNode.node.title} */}
                  </Link>
                </motion.div>
                <>
                  {orderedMilan.map((studio, _i) => {
                    return (
                      <motion.div
                        className="link sub-link"
                        variants={children}
                        key={`studio_${_i}`}
                      >
                        <Link
                          activeClassName="active"
                          to={"/studios/milan/" + studio.node.slug}
                          state={{ page: studio.node.slug }}
                        >
                          {studio.node.title}
                        </Link>
                      </motion.div>
                    )
                  })}
                </>
                <motion.div className="link" variants={children} key={i}>
                  <Link
                    activeClassName="active"
                    to={"/studios/rome/"}
                    state={{ page: node.path }}
                    onClick={() => handleClick(node.path)}
                  >
                    Roma
                    {/* {node.connectedNode.node.title} */}
                  </Link>
                </motion.div>
                <>
                  {rome.edges.map((studio, _i) => {
                    return (
                      <motion.div
                        className="link sub-link"
                        variants={children}
                        key={`studio_${_i}`}
                      >
                        <Link
                          activeClassName="active"
                          to={"/studios/rome/" + studio.node.slug}
                          state={{ page: studio.node.slug }}
                        >
                          {studio.node.title}
                        </Link>
                      </motion.div>
                    )
                  })}
                </>
              </>
            )
          } else {
            if (node.connectedNode.node.ancestors === null) {
              return (
                <motion.div className="link" variants={children} key={i}>
                  <Link
                    activeClassName="active"
                    to={node.path}
                    state={{ page: node.path }}
                    onClick={() => handleClick(node.path)}
                  >
                    {node.connectedNode.node.title}
                  </Link>
                </motion.div>
              )
            } else if (
              node.connectedNode.node.ancestors.nodes[0].slug !== "studios"
            ) {
              return (
                <motion.div
                  className="link sub-link"
                  variants={children}
                  key={i}
                >
                  <Link
                    activeClassName="active"
                    to={node.path}
                    state={{ page: node.path }}
                  >
                    {node.connectedNode.node.title}
                  </Link>
                </motion.div>
              )
            } else {
              return null
            }
          }
        })}
      </MenuWrapper>
    </>
  )
}

export default Menu
