import React, { useState, useEffect, useCallback } from "react"
import styled from "styled-components"

import { Link } from "gatsby"
import { motion, AnimatePresence } from "framer-motion"

import useScrollBlock from "../hooks/useScrollBlock"
import useWindowSize from "../hooks/useWindowDimensions"

import { useWpPrivacyPage } from "../hooks/wp/usePrivacyPage"

import fondo from "../../static/fondo-news.svg"
// import mask from "../../static/mask.svg"
import Menu from "./Menu"
import Title from "./Layout/Title"

import "../styles/variables.scss"
import "../styles/global.scss"

const Header = styled.header`
  mix-blend-mode: difference;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;

  height: var(--navHeight);

  padding-right: var(--pagePadding);
  padding-left: var(--pagePadding);

  // width: calc(100% - var(--pagePadding));
  width: 100vw;

  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;

  .menu-toggler {
    min-width: calc(var(--menuBtnSize) * 2);
    min-height: calc(var(--menuBtnSize) * 2);
    max-width: calc(var(--menuBtnSize) * 2);
    max-height: calc(var(--menuBtnSize) * 2);

    .default-circle {
      min-width: var(--menuBtnSize);
      min-height: var(--menuBtnSize);
      max-width: var(--menuBtnSize);
      max-height: var(--menuBtnSize);
      border-radius: 50%;
      background-color: white;
    }
    background: transparent;
    color: transparent;
    border: none;
    padding: 0;

    &:hover {
      cursor: pointer;
    }
  }

  a#site-brand {
    font-family: CircusWeb !important;
    text-decoration: none;
    font-size: var(--logoSize);
    color: white;
  }

  @media (max-width: 768px) {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    a#site-brand {
      text-align: right;
    }
  }
`

const SimpleLogoMenu = styled.header`
  // background-image: url(${fondo});
  // background-size: contain;
  // background-repeat: repeat-y;

  mix-blend-mode: difference;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 2;
  height: var(--navHeight);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding-right: var(--pagePadding);
  padding-left: var(--pagePadding);

  a#site-brand {
    font-family: CircusWeb !important;
    text-decoration: none;
    font-size: var(--logoSize);
    color: white;
  }
`

const menuBtn = {
  initial: {
    opacity: 0,
  },
  animate: {
    transition: {
      opacity: {
        delay: 0.3,
      },
    },
    opacity: 1,
  },
}

const FixedNavigation = ({ children, location }) => {
  // console.log("Website by:")
  // console.log(
  //   "This website was made by: studio@giammarcogaudenzi.com, info@dariopianesi.com, ciao@veronicaalba.com"
  // )
  const { width } = useWindowSize()

  const [menuState, setMenuState] = useState(false)
  const [blockScroll, allowScroll] = useScrollBlock()
  const [isArticleOpen, setIsArticleOpen] = useState(false)

  useEffect(() => {
    setMenuState(false)
  }, [location.pathname])

  const [cookieConsent, setCookieConsent] = useState(false)
  const [cookieSidebarState, setCookieSidebarState] = useState(false)
  useEffect(() => {
    menuState || cookieSidebarState ? blockScroll() : allowScroll()
  }, [menuState, cookieSidebarState, blockScroll, allowScroll])

  useEffect(() => {
    if (localStorage.getItem("agree")) {
      setCookieConsent(true)
    }
  }, [])

  const handleConsense = () => {
    localStorage.setItem("agree", "true")
    setCookieConsent(true)
  }

  const keyNavigation = useCallback(event => {
    // console.log(event.keyCode)
    if (event.keyCode === 27) {
      setMenuState(false)
    }
  }, [])
  useEffect(() => {
    document.addEventListener("keydown", keyNavigation, false)
    return () => {
      document.removeEventListener("keydown", keyNavigation, false)
    }
  })

  return (
    <>
      {!isArticleOpen ? (
        location.pathname !== "/" && (
          <Header isArticleOpen={isArticleOpen}>
            <AnimatePresence>
              <motion.button
                variants={menuBtn}
                initial="initial"
                animate="animate"
                className="menu-toggler"
                onClick={() => setMenuState(!menuState)}
              >
                <div className="default-circle" />
              </motion.button>
            </AnimatePresence>
            <Link id="site-brand" to="/">
              {width < 768 ? "C" : "Circus"}
            </Link>
          </Header>
        )
      ) : (
        <SimpleLogoMenu>
          <Link id="site-brand" to="/">
            {width < 768 ? "C" : "Circus"}
          </Link>
        </SimpleLogoMenu>
      )}

      <AnimatePresence>
        {menuState && (
          <Menu
            menuState={menuState}
            setMenuState={setMenuState}
            width={width}
            location={location}
          />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {!cookieConsent && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <CookieBanner
              cookieSidebarState={cookieSidebarState}
              setCookieSidebarState={setCookieSidebarState}
              handleConsense={handleConsense}
              width={width}
            />
          </motion.div>
        )}
      </AnimatePresence>

      {React.cloneElement(children, {
        setIsArticleOpen: setIsArticleOpen,
      })}
    </>
  )
}

export default FixedNavigation

const CookiesContainer = styled(motion.div)`
  // border-right: 1px solid black;
  top: 0;
  width: 100%;
  &.opened {
    // box-shadow: 12px 12px 12px black;
    border-right: 1px solid black;
    background: white;
  }
  &.closed {
    background: transparent;
  }

  .close-button {
    position: absolute;
    top: 1em;
    left: 1em;
    background: transparent;
    border: 0;
    text-transform: uppercase;
    padding: 0.4em 1em;
    padding-left: 0.3em;
  }

  svg {
    border: 2px solid blue;
    width: 100%;
    height: 100vh;
  }

  width: 50%;
  height: 100%;
  max-height: 100vh;

  @media screen and (max-width: 768px) {
    width: 90%;
  }

  position: fixed;
  z-index: 1;
  .cookie-sidebar-nav {
    position: fixed;
    top: 0;
  }
  .button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    position: absolute;
    top: 0;
    right: 0;
    padding: 1em;

    button {
      border: 0;
      background-color: transparent;
      text-transform: uppercase;
      margin: 0;
      padding: 0.4em 1em;
      &:hover {
        cursor: pointer;
      }

      a {
        text-decoration: none;
      }
    }
  }

  .content {
    max-height: 100vh;
    overflow-y: scroll;
    padding: 0 1em;
    padding-bottom: calc(80px + 1em);
    width: 100%;

    .testosplash {
      margin-top: 80px;
      margin-bottom: 80px;
    }
  }
`

const MiniatureCookieNavbar = styled.div`
  .cookie-banner-toggler {
    position: fixed;
    z-index: 8;
    width: 1.2em;
    display: flex;
    align-items: center;
    justify-content: center;

    .interface-wrapper {
      display: flex;
      flex-direction: row;
      transform: rotate(-90deg);
      font-size: 0.7rem;

      button {
        white-space: nowrap;
        text-align: center;
        background: transparent;
        text-transform: uppercase;

        padding: 0;
        border: 0;

        &:hover {
          cursor: pointer;
        }
      }
      .read-button {
      }
      .accept-button {
      }
    }
    background: transparent;
    border: none;
    // width: 100%;
    height: 100%;

    &:hover {
      cursor: pointer;
    }
  }
`
const OverlayClose = styled.button`
  // border: 2px solid orange;
  position: fixed;

  z-index: 99;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  opacity: 0;
  &:hover {
    cursor: pointer;
  }
`
const cookieAnimation = {
  initial: {
    x: "-100%",
    pointerEvents: "none",
    zIndex: 1,
  },
  open: {
    x: "0%",
    zIndex: 3,
    pointerEvents: "all",
  },
  close: {
    zIndex: 1,
    pointerEvents: "none",
  },
}
const CookieBanner = ({
  handleConsense,
  setCookieSidebarState,
  cookieSidebarState,
  width,
}) => {
  const { wpPage } = useWpPrivacyPage()
  const privacyPage = wpPage

  return (
    <>
      {cookieSidebarState && (
        <OverlayClose
          onClick={() => setCookieSidebarState(!cookieSidebarState)}
          className="overlay-close"
        >
          INVISIBLE CLOSE
        </OverlayClose>
      )}
      <MiniatureCookieNavbar>
        <AnimatePresence>
          {!cookieSidebarState && (
            <motion.div
              className="cookie-banner-toggler"
              animate={
                cookieSidebarState
                  ? {
                      pointerEvents: "none",
                      opacity: 0,
                      display: "none",
                      transition: {
                        duration: 0,
                      },
                    }
                  : {
                      opacity: 1,
                    }
              }
              // onClick={() => !cookieSidebarState && setCookieSidebarState(true)}
            >
              <div className="interface-wrapper">
                <button
                  className="read-button"
                  onClick={() =>
                    !cookieSidebarState && setCookieSidebarState(true)
                  }
                >
                  privacy policy read/
                </button>
                <button
                  className="accept-button"
                  onClick={() => handleConsense()}
                >
                  accept
                </button>
              </div>

              {/* <span>privacy policy read/accept</span> */}
            </motion.div>
          )}
        </AnimatePresence>
      </MiniatureCookieNavbar>
      <CookiesContainer
        className={cookieSidebarState ? "opened" : "closed"}
        variants={cookieAnimation}
        initial="initial"
        animate={cookieSidebarState ? "open" : "close"}
        exit="exit"
      >
        {cookieSidebarState && (
          <>
            <motion.div className="button-wrapper">
              <button
                className="read-more"
                onClick={() => setCookieSidebarState(false)}
              >
                <Link to="/privacy-policy/">Read more</Link>
              </button>
              <button className="accept" onClick={() => handleConsense()}>
                Accept
              </button>
            </motion.div>
            <button
              className="close-button"
              onClick={() => setCookieSidebarState(!cookieSidebarState)}
            >
              GO BACK
            </button>
          </>
        )}

        {cookieSidebarState && (
          <>
            <div className="content">
              <Title
                key={`title_${cookieSidebarState}`}
                data={{ title: privacyPage.title }}
                width={width}
              />
              <div
                dangerouslySetInnerHTML={{
                  __html: `${privacyPage.content}`,
                }}
              />
            </div>
          </>
        )}
      </CookiesContainer>
    </>
  )
}
