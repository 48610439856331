import React, { useState, useRef, useEffect } from "react"
import { AutoTextSize } from "auto-text-size"
import { window } from "browser-monads"
import useResizeObserver from "use-resize-observer"

const AutoTitle = ({ children }) => {
  const ref = useRef(null)
  const [containerHeight, setContainerHeight] = useState(null)
  const { width } = useResizeObserver({ ref })

  useEffect(() => {
    setTimeout(() => {
      const node = ref.current?.childNodes[0]

      if (node) {
        const computedStyle = window.getComputedStyle(node)
        const fontSize = parseInt(computedStyle.fontSize, 10)
        setContainerHeight(fontSize)
      }
    }, 100)
  }, [width])

  return (
    <div
      ref={ref}
      className={`px-2 w-[100%] flex flex-col items-center justify-center transition-all duration-200 uppercase`}
      style={{ height: containerHeight }}
    >
      <AutoTextSize mode="oneline" maxFontSizePx={800}>
        {children}
      </AutoTextSize>
    </div>
  )
}

export default AutoTitle
