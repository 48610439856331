import { useStaticQuery, graphql } from "gatsby"

export const useWpPrivacyPage = () => {
  const data = useStaticQuery(graphql`
    query {
      wpPage(slug: { eq: "privacy-policy" }) {
        title
        id
        content
      }
    }
  `)

  return data
}
