import { useStaticQuery, graphql } from "gatsby"

export const useWpMenuQuery = () => {
  const data = useStaticQuery(graphql`
    query {
      wpMenu(name: { eq: "PrimaryNav" }) {
        menuItems {
          nodes {
            path
            url
            order
            connectedNode {
              node {
                ... on WpPage {
                  title
                  ancestors {
                    nodes {
                      slug
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return data
}
